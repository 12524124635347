<div class="modal-wrapper">
	<div class="modal-confirmation">
		<div class="modal-confirmation__container">
			<h3 class="jbl-heading-3 jbl-600 modal-confirmation__container-header">Invite Owner</h3>
			<h5 class="jbl-heading-5 modal-confirmation__container-text">Select from {{ usersList.length }} available accounts</h5>
		</div>
		<div class="modal-body">
			<input
				class="jbl-input input"
				type="text"
				maxlength="100"
				placeholder="Search by account name or email"
				[formControl]="searchControl" />

			<form class="factories-form" [formGroup]="form">
				@for (user of visibleUsersList; track user.username) {
					<div class="df-checkbox-field" [class.disabled]="alreadyOwnersIds.includes(user.username)">
						<input class="jbl-checkbox" type="checkbox" [id]="user.email" [formControlName]="user.username" [value]="false" />
						<label [for]="user.email">{{ user.fullName }} ({{ user.email }})</label>
					</div>
				}
			</form>
		</div>
		<div class="modal-confirmation__action">
			<button class="modal-confirmation__action-secondary jbl-btn jbl-btn-sm jbl-btn-secondary" type="button" (click)="dismiss()">
				Cancel
			</button>
			<button
				class="modal-confirmation__action-primary jbl-btn jbl-btn-sm jbl-btn-primary"
				type="button"
				(click)="inviteSelectedOwners()"
				[disabled]="!isFormInvalid">
				Invite
			</button>
		</div>
	</div>
</div>
