import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { InviteContributorsModalComponent } from '@components/modals/invite-contributors-modal/invite-contributors-modal.component';
import { InviteOwnersModalComponent } from '@components/modals/invite-owners-modal/invite-owners-modal.component';
import { MiRoutes } from '@core/configs/routes';
import { AppSecurityUser } from '@core/services/app-security/app-security.service';
import { RoleNamesMap } from '@core/services/cerbos/cerbos.constants';
import {
	IfAllowContributorsInvitesDirective,
	IfAllowOwnersInvitesDirective
} from '@core/services/cerbos/resources/quote/quote-resource.directive';
import { ConfigService } from '@core/services/config/config.service';
import { UserOwnershipInviteParams } from '@interfaces/quote.interface';
import { JblToastService } from '@jbl-pip/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuotesStore } from '@stores/quotes.store';
import { catchError, filter, map, merge, of } from 'rxjs';

export const OWNER_INVITE_TOAST = {
	INVITE_SUCCESS: {
		body: 'Invitation sent successfully via email.'
	},
	INVITE__FAIL: {
		body: 'Something went wrong. Please try again.'
	}
};

export const CONTRIBUTOR_INVITE_TOAST = {
	INVITE_SUCCESS: {
		body: 'Invitation sent successfully via email.'
	},
	INVITE__FAIL: {
		body: 'Something went wrong. Please try again.'
	}
};

@Component({
	selector: 'mi-nav-sub-header',
	standalone: true,
	templateUrl: './nav-sub-header.component.html',
	styleUrl: './nav-sub-header.component.scss',
	imports: [IfAllowOwnersInvitesDirective, IfAllowContributorsInvitesDirective]
})
export class NavSubHeaderComponent {
	private readonly modalService = inject(NgbModal);
	readonly quotesStore = inject(QuotesStore);
	readonly configService = inject(ConfigService);
	readonly toastService = inject(JblToastService);
	readonly router = inject(Router);
	readonly route = inject(ActivatedRoute);
	private readonly roleNamesMap = RoleNamesMap;

	currentRole = computed(() => {
		const user = this.configService.currentUser();

		if (!user || !user.role) {
			return 'Unknown role';
		}

		return user.role in this.roleNamesMap ? this.roleNamesMap[user.role] : user.role;
	});

	isClosed = computed(() => {
		const currentQuote = this.quotesStore.currentQuote();
		const status = currentQuote ? currentQuote.status : 'Closed';

		return status === 'Closed';
	});

	showInviteButtons = toSignal(
		merge(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			this.route.data.pipe(map(() => (this.route.snapshot as any)._routerState.url)),
			this.router.events.pipe(
				filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd),
				map((e: NavigationEnd) => e.urlAfterRedirects)
			)
		).pipe(map((url: string) => url.includes(MiRoutes.quotes.url)))
	);

	// ikurc TODO: Rework this method to use switchMap, reogranize structure
	openInviteOwnersModal(): void {
		const users = this.configService.appSecurityUsersWithLocalUpdates();
		const modalRef = this.modalService.open(InviteOwnersModalComponent, { centered: true });

		modalRef.componentInstance.usersList = users.filter((user: AppSecurityUser) => {
			const isAlreadyOwner = user.ownership.owns.includes(this.quotesStore.state.currentQuoteId());
			const isAlreadyContributor = user.ownership.contributes.includes(this.quotesStore.state.currentQuoteId());

			return !isAlreadyOwner && !isAlreadyContributor;
		});

		modalRef.closed.pipe(filter(Boolean)).subscribe((usersData: UserOwnershipInviteParams[]) => {
			const userIds = usersData.map((user: UserOwnershipInviteParams) => user.userId);
			const quoteId = this.quotesStore.state.currentQuoteId();

			this.quotesStore
				.inviteOwners(usersData)
				.pipe(
					catchError(() => {
						this.toastService.danger(OWNER_INVITE_TOAST.INVITE__FAIL);

						return of(null);
					})
				)
				.subscribe(data => {
					if (data) {
						this.configService.updateOwnershipLocally(quoteId, userIds, 'owns');
						this.toastService.success(OWNER_INVITE_TOAST.INVITE_SUCCESS);
					}
				});
		});
	}

	// ikurc TODO: Rework this method to use switchMap, reogranize structure
	openInviteContributorsModal(): void {
		const users = this.configService.appSecurityUsersWithLocalUpdates();
		const modalRef = this.modalService.open(InviteContributorsModalComponent, { centered: true });

		modalRef.componentInstance.usersList = users.filter((user: AppSecurityUser) => {
			const isAlreadyOwner = user.ownership.owns.includes(this.quotesStore.state.currentQuoteId());
			const isAlreadyContributor = user.ownership.contributes.includes(this.quotesStore.state.currentQuoteId());

			return !isAlreadyOwner && !isAlreadyContributor;
		});

		modalRef.closed.pipe(filter(Boolean)).subscribe((usersData: UserOwnershipInviteParams[]) => {
			const userIds = usersData.map((user: UserOwnershipInviteParams) => user.userId);
			const quoteId = this.quotesStore.state.currentQuoteId();

			this.quotesStore
				.inviteContributors(usersData)
				.pipe(
					catchError(() => {
						this.toastService.danger(CONTRIBUTOR_INVITE_TOAST.INVITE__FAIL);

						return of(null);
					})
				)
				.subscribe(data => {
					if (data) {
						this.configService.updateOwnershipLocally(quoteId, userIds, 'contributes');
						this.toastService.success(CONTRIBUTOR_INVITE_TOAST.INVITE_SUCCESS);
					}
				});
		});
	}
}
